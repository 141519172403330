import { ReactNode, memo } from "react"
import { ActiveLinkAtom } from "ui-atoms-next"

interface ComponentLinkProps {
  href?: string
  children: ReactNode
  soon?: boolean
}

export const ComponentLink: React.FC<ComponentLinkProps> = memo(
  (props: ComponentLinkProps) => {
    return (
      <ActiveLinkAtom
        className="block w-full rounded px-3 py-2 text-sm font-medium text-zinc-300 no-underline"
        disabledClassName="opacity-50"
        activeClassName="bg-zinc-700/70"
        disabled={props.soon}
        href={`/studio${props.href || (props.soon ? "/soon" : "")}`}
      >
        {props.children}
        {props.soon && (
          <span className="ml-2 mt-px rounded-lg border border-zinc-600 px-1.5 py-0.5 text-xs">
            Soon
          </span>
        )}
      </ActiveLinkAtom>
    )
  }
)

ComponentLink.displayName = "ComponentLink"
