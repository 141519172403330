import { ReactNode, memo } from "react"

interface ComponentGroupProps {
  children: ReactNode
  icon: React.FC
  title: string
}

export const ComponentGroup: React.FC<ComponentGroupProps> = memo(
  (props: ComponentGroupProps) => {
    return (
      <>
        <div className="flex items-center space-x-2 pb-2 pt-6 text-zinc-400">
          <div className="h-6 w-6">
            <props.icon />
          </div>
          <div className="text-sm font-medium text-zinc-400">{props.title}</div>
        </div>
        <div className="relative pl-5">
          <div className="absolute bottom-3 left-3 top-0 w-px bg-zinc-700" />
          {props.children}
        </div>
      </>
    )
  }
)

ComponentGroup.displayName = "ComponentGroup"
