var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/ActiveLinkAtom.tsx
import { useRouter } from "next/router";
import Link from "next/link";
import { memo, useEffect, useState } from "react";
import { jsx } from "react/jsx-runtime";
var ActiveLinkAtom = memo(
  (_a) => {
    var _b = _a, {
      className,
      activeClassName,
      inactiveClassName,
      disabledClassName,
      children
    } = _b, props = __objRest(_b, [
      "className",
      "activeClassName",
      "inactiveClassName",
      "disabledClassName",
      "children"
    ]);
    const { asPath, isReady } = useRouter();
    const [computedClassName, setComputedClassName] = useState(className);
    const [active, setActive] = useState(false);
    let defaultClassNames = "";
    if (props.disabled) {
      defaultClassNames = "pointer-events-none";
    }
    useEffect(() => {
      if (isReady) {
        const linkPathname = new URL(
          props.as || props.href,
          location.href
        ).pathname;
        const activePathname = new URL(asPath, location.href).pathname;
        const newClassName = linkPathname === activePathname ? `${className || ""} ${defaultClassNames} ${props.disabled ? disabledClassName || "" : ""} ${activeClassName || ""}`.trim() : `${className || ""} ${defaultClassNames} ${(props.disabled ? disabledClassName || "" : "").trim()} ${inactiveClassName || ""}`.trim();
        setActive(linkPathname === activePathname);
        if (newClassName !== computedClassName) {
          setComputedClassName(newClassName);
        }
      }
    }, [
      activeClassName,
      asPath,
      className,
      computedClassName,
      defaultClassNames,
      disabledClassName,
      inactiveClassName,
      isReady,
      props.as,
      props.disabled,
      props.href
    ]);
    return /* @__PURE__ */ jsx(Link, __spreadProps(__spreadValues({ className: computedClassName }, props), { children: typeof children === "function" ? children(active) : children }));
  }
);
ActiveLinkAtom.displayName = "ActiveLinkAtom";

// src/HeadAtom.tsx
import Head from "next/head";
import { memo as memo2 } from "react";
import { getHeadTitle } from "utils";
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
var HeadAtom = memo2(
  (props) => {
    const title = getHeadTitle(props.appName, props.appDescription, void 0);
    return /* @__PURE__ */ jsxs(Head, { children: [
      /* @__PURE__ */ jsx2("title", { children: title }),
      /* @__PURE__ */ jsx2("meta", { property: "og:title", content: props.appName }),
      /* @__PURE__ */ jsx2("meta", { property: "og:image", content: `${props.baseUrl}/og.jpg` }),
      /* @__PURE__ */ jsx2("meta", { property: "og:image:type", content: "image/jpg" }),
      /* @__PURE__ */ jsx2("meta", { property: "og:image:width", content: "1200" }),
      /* @__PURE__ */ jsx2("meta", { property: "og:image:height", content: "627" }),
      /* @__PURE__ */ jsx2("meta", { property: "description", content: props.appDescription }),
      /* @__PURE__ */ jsx2("meta", { property: "og:description", content: props.appDescription }),
      /* @__PURE__ */ jsx2("meta", { property: "og:type", content: "website" }),
      /* @__PURE__ */ jsx2("meta", { name: "viewport", content: "width=device-width, initial-scale=1" }),
      /* @__PURE__ */ jsx2(
        "meta",
        {
          name: "theme-color",
          media: "(prefers-color-scheme: light)",
          content: props.lightBackgroundColor
        }
      ),
      /* @__PURE__ */ jsx2(
        "meta",
        {
          name: "theme-color",
          media: "(prefers-color-scheme: dark)",
          content: props.darkBackgroundColor
        }
      ),
      /* @__PURE__ */ jsx2(
        "meta",
        {
          name: "msapplication-TileColor",
          content: props.lightBackgroundColor
        }
      ),
      /* @__PURE__ */ jsx2(
        "link",
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png"
        }
      ),
      /* @__PURE__ */ jsx2(
        "link",
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png"
        }
      ),
      /* @__PURE__ */ jsx2(
        "link",
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png"
        }
      ),
      /* @__PURE__ */ jsx2("link", { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#5bbad5" }),
      /* @__PURE__ */ jsx2("link", { rel: "manifest", href: "/site.webmanifest" })
    ] });
  }
);
HeadAtom.displayName = "HeadAtom";
export {
  ActiveLinkAtom,
  HeadAtom
};
