import {
  AdjustmentsHorizontalIcon,
  CodeBracketIcon,
  CubeTransparentIcon,
  DevicePhoneMobileIcon,
  EyeDropperIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline"
import Link from "next/link"
import Head from "next/head"
import StudioLayout from "../components/StudioLayout"

function StudioPage() {
  return (
    <div className="pb-8 text-white">
      <Head>
        <title>
          Frontender Studio | Create advanced UI components blazing fast
        </title>
        <meta name="theme-color" content="#18181b" />
        <meta
          property="og:image"
          content="https://www.frontender.io/og-studio.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:title"
          content="Frontender Studio | Create advanced UI components blazing fast"
        />
        <meta
          property="description"
          content="Build an accessible and performant UI library in minutes, not months. Following industry best practices, Frontender combines the ease-of-use of a component library with infinite customization—and a visual editor to make it incredibly easy."
        />
        <meta
          property="og:description"
          content="Build an accessible and performant UI library in minutes, not months. Following industry best practices, Frontender combines the ease-of-use of a component library with infinite customization—and a visual editor to make it incredibly easy."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.frontender.io/" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Head>
      <div className="flex space-x-3">
        <div className="flex items-center space-x-2 rounded-full bg-sky-900/80 px-3 py-1.5 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-11.5 -10.23174 23 20.46348"
            className="h-5 w-5"
          >
            <circle cx="0" cy="0" r="2.05" fill="#61dafb" />
            <g stroke="#61dafb" strokeWidth="1" fill="none">
              <ellipse rx="11" ry="4.2" />
              <ellipse rx="11" ry="4.2" transform="rotate(60)" />
              <ellipse rx="11" ry="4.2" transform="rotate(120)" />
            </g>
          </svg>
          <div>React</div>
        </div>
        <div className="flex items-center space-x-2 rounded-full bg-sky-800 px-3 py-1.5 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 54 33"
            className="h-5 w-5"
          >
            <g clipPath="url(#prefix__clip0)">
              <path
                fill="#38bdf8"
                fillRule="evenodd"
                d="M27 0c-7.2 0-11.7 3.6-13.5 10.8 2.7-3.6 5.85-4.95 9.45-4.05 2.054.513 3.522 2.004 5.147 3.653C30.744 13.09 33.808 16.2 40.5 16.2c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C36.756 3.11 33.692 0 27 0zM13.5 16.2C6.3 16.2 1.8 19.8 0 27c2.7-3.6 5.85-4.95 9.45-4.05 2.054.514 3.522 2.004 5.147 3.653C17.244 29.29 20.308 32.4 27 32.4c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C23.256 19.31 20.192 16.2 13.5 16.2z"
                clipRule="evenodd"
              />
            </g>
            <defs>
              <clipPath id="prefix__clip0">
                <path fill="#fff" d="M0 0h54v32.4H0z" />
              </clipPath>
            </defs>
          </svg>
          <div>Tailwind</div>
        </div>
      </div>
      <h1 className="mt-5 max-w-2xl text-4xl/tight font-extrabold tracking-tight sm:text-5xl/[1.15]">
        Create advanced UI components{" "}
        <span className="bg-gradient-to-r from-sky-300 to-fuchsia-400 bg-clip-text text-transparent">
          blazing fast
        </span>
        .
      </h1>
      <p className="mt-4 max-w-3xl text-lg/relaxed text-zinc-400 sm:text-xl/relaxed">
        Build an accessible and performant UI library in minutes, not months.
        Following industry best practices, Frontender combines the ease-of-use
        of a component library with infinite customization&mdash;and a visual
        editor to make it incredibly easy.
      </p>
      <div className="mt-8 grid max-w-screen-lg gap-6 lg:grid-cols-2 xl:grid-cols-3">
        <div className="space-y-4 rounded-lg bg-sky-900/40 p-6 lg:aspect-square">
          <CubeTransparentIcon className="h-6 w-6 text-sky-400" />
          <p className="text-lg font-semibold leading-normal text-sky-100">
            Start with a blueprint based on best practices{" "}
            <span className="text-sky-400">
              that contains code for often-used variants and styling, so you
              never have to write any boilerplate code.
            </span>
          </p>
        </div>
        <div className="space-y-4 rounded-lg bg-purple-900/40 p-6 lg:aspect-square">
          <EyeDropperIcon className="h-6 w-6 text-purple-400" />
          <p className="text-lg font-semibold leading-normal text-purple-100">
            Customize anything in the visual web editor{" "}
            <span className="text-purple-400">
              to make your component unique in both style and function, and see
              the code update in real-time.
            </span>
          </p>
        </div>
        <div className="space-y-4 rounded-lg bg-yellow-900/40 p-6 lg:aspect-square">
          <AdjustmentsHorizontalIcon className="h-6 w-6 text-yellow-400" />
          <p className="text-lg font-semibold leading-normal text-yellow-100">
            Define props and conditional styles{" "}
            <span className="text-yellow-400">
              for hover, focus and disabled states, variants like
              &apos;primary&apos; and &apos;outline&apos;, light and dark mode,
              and more&mdash;and let Frontender write the complex component code
              for you.
            </span>
          </p>
        </div>
        <div className="space-y-4 rounded-lg bg-emerald-900/40 p-6 lg:aspect-square">
          <DevicePhoneMobileIcon className="h-6 w-6 text-emerald-400" />
          <p className="text-lg font-semibold leading-normal text-emerald-100">
            Create adaptive interfaces{" "}
            <span className="text-emerald-400">
              and allow different components to be displayed on different
              devices, like a dropdown that becomes a tray on mobile.
            </span>
          </p>
        </div>
        <div className="space-y-4 rounded-lg bg-pink-900/40 p-6 lg:aspect-square">
          <CodeBracketIcon className="h-6 w-6 text-pink-400" />
          <p className="text-lg font-semibold leading-normal text-pink-100">
            Copy-paste the generated code{" "}
            <span className="text-pink-400">
              into your code editor when it&apos;s{" "}
              <span className="italic">just</span> right. Frontender is not a
              package, so you have complete freedom over the code and how you
              use it.
            </span>
          </p>
        </div>
        <Link
          href="/pricing"
          className="space-y-4 rounded-lg bg-zinc-900 p-6 no-underline transition-colors hover:bg-zinc-900/50 lg:aspect-square"
        >
          <SparklesIcon className="h-6 w-6 text-zinc-400" />
          <p className="text-lg font-semibold leading-normal text-zinc-100">
            Support this project and get all-access{" "}
            <span className="text-zinc-400">
              when the first components are ready later this year. &rarr;
            </span>
          </p>
        </Link>
      </div>
    </div>
  )
}

StudioPage.displayName = "StudioPage"
StudioPage.getLayout = StudioLayout
export default StudioPage
