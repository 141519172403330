import { ReactElement } from "react"
import {
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon,
  CursorArrowRippleIcon,
  MapIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline"
import { motion } from "framer-motion"
import { Header } from "./Header"
import { ComponentLink } from "./ComponentLink"
import { ComponentGroup } from "./ComponentGroup"

const headerVariants = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
}

const rowVariants1 = {
  animate: {
    opacity: 1,
    transition: {
      delay: 0.05,
    },
  },
  initial: {
    opacity: 0,
  },
}

const rowVariants2 = {
  animate: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  initial: {
    opacity: 0,
  },
}

export default function StudioLayout(page: ReactElement) {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      className="flex h-screen select-none bg-gradient-to-b from-zinc-900 via-zinc-900 to-zinc-800 p-4"
    >
      <div className="flex h-full flex-1 flex-col border-zinc-700 p-4 pb-0 pt-1 sm:overflow-hidden sm:border sm:p-7 sm:pb-0 sm:pt-3">
        <Header />
        <motion.div
          variants={headerVariants}
          className="mt-4 rounded bg-amber-900/50 p-3 text-sm font-medium text-zinc-300 sm:text-center"
        >
          <span className="font-bold text-zinc-100">Heads up:</span> Frontender
          Studio is currently in private beta, with the public release expected
          mid 2023.{" "}
          <a
            href="https://www.twitter.com/jeroenmakes"
            target="_blank"
            rel="noopener noreferrer"
            className="decoration-amber-700 underline-offset-4 hover:text-zinc-100"
          >
            Subscribe to updates on Twitter.
          </a>
        </motion.div>
        <motion.div
          variants={rowVariants1}
          className="flex flex-1 pt-8 sm:overflow-hidden"
        >
          <div className="mr-16 hidden h-full w-64 shrink-0 overflow-scroll pb-8 md:block">
            <ComponentLink href="">Getting started</ComponentLink>
            <ComponentGroup icon={CursorArrowRippleIcon} title="Actions">
              <ComponentLink soon>Button</ComponentLink>
              <ComponentLink soon>Button group</ComponentLink>
              <ComponentLink soon>Link</ComponentLink>
              <ComponentLink soon>Toolbar</ComponentLink>
            </ComponentGroup>
            <ComponentGroup icon={RectangleGroupIcon} title="Content">
              <ComponentLink soon>Accordion</ComponentLink>
              <ComponentLink soon>Badge</ComponentLink>
              <ComponentLink soon>Callout</ComponentLink>
              <ComponentLink soon>Card</ComponentLink>
            </ComponentGroup>
            <ComponentGroup
              icon={ChatBubbleBottomCenterTextIcon}
              title="Events & status"
            >
              <ComponentLink soon>Progress</ComponentLink>
              <ComponentLink soon>Spinner</ComponentLink>
              <ComponentLink soon>Status light</ComponentLink>
              <ComponentLink soon>Toast</ComponentLink>
            </ComponentGroup>
            <ComponentGroup icon={CheckCircleIcon} title="Inputs">
              <ComponentLink soon>Checkbox</ComponentLink>
              <ComponentLink soon>Checkbox group</ComponentLink>
              <ComponentLink soon>Color picker</ComponentLink>
              <ComponentLink soon>Input</ComponentLink>
              <ComponentLink soon>Label</ComponentLink>
              <ComponentLink soon>Select</ComponentLink>
              <ComponentLink soon>Radio group</ComponentLink>
              <ComponentLink soon>Rating</ComponentLink>
              <ComponentLink soon>Slider</ComponentLink>
              <ComponentLink soon>Switch</ComponentLink>
              <ComponentLink soon>Textarea</ComponentLink>
              <ComponentLink soon>Toggle</ComponentLink>
              <ComponentLink soon>Toggle group</ComponentLink>
            </ComponentGroup>
            <ComponentGroup icon={Bars3Icon} title="Menus">
              <ComponentLink soon>Command menu</ComponentLink>
              <ComponentLink soon>Context menu</ComponentLink>
              <ComponentLink soon>Dropdown menu</ComponentLink>
            </ComponentGroup>
            <ComponentGroup icon={MapIcon} title="Navigation">
              <ComponentLink soon>Breadcrumbs</ComponentLink>
              <ComponentLink soon>Footer</ComponentLink>
              <ComponentLink soon>Header</ComponentLink>
              <ComponentLink soon>Sidebar</ComponentLink>
              <ComponentLink soon>Tabs</ComponentLink>
              <ComponentLink soon>Tab bar</ComponentLink>
            </ComponentGroup>
            <ComponentGroup icon={RectangleStackIcon} title="Overlays">
              <ComponentLink soon>Alert dialog</ComponentLink>
              <ComponentLink soon>Dialog</ComponentLink>
              <ComponentLink soon>Popover</ComponentLink>
              <ComponentLink soon>Tooltip</ComponentLink>
            </ComponentGroup>
            <ComponentGroup icon={UserCircleIcon} title="User management">
              <ComponentLink soon>Avatar</ComponentLink>
              <ComponentLink soon>Login form</ComponentLink>
              <ComponentLink soon>Registration form</ComponentLink>
              <ComponentLink soon>Profile settings</ComponentLink>
            </ComponentGroup>
          </div>
          <motion.div
            variants={rowVariants2}
            className="h-full flex-1 sm:overflow-scroll"
          >
            {page}
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}
